

import React from "react";
import NewLogin from "../src/components/auth/newLogin";
import { withRouter } from "next/router";
class Home extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    return <NewLogin />;
  }
}

export default withRouter(Home);
